import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  StyleRules,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Cascader } from 'antd';
import { navigate } from 'gatsby';

import { addCompanyRequest } from '../../actions/companyActions';
// import { navigate } from 'gatsby';
import {
  CancelCompanyPublishProjectDialog,
  CompanyPublishProjectDialog,
  EditCompanyPublishProjectDialog,
} from '../../components/Dialog';
import { showNotificationAction } from '../../reducers/notificationReducer';
import CITY from '../../utils/city';
import countyLess from '../../utils/cityChild';
import dataIndustry from '../../utils/industry';
import PROVINCE from '../../utils/province';
import specialData from '../../utils/special';
import CertificateInfoListView from '../company/CertificateInfoListView';
import {
  createCompanyRequestInfo,
  createDemandCertificateInfo,
} from '../company/companyRequest.model';
import LanguageInputView from '../company/CompanyRequestLanguagesInputView';
import { createConsultantLanguageInfo } from '../consultant/consultant.model';
import ConsultantComment from './ConsultantComment';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      maxWidth: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },

    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    dataGridTable: {
      height: 200,
      width: '100%',
    },
    childrenItem: {
      background: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
      borderRadius: '3px',
      padding: '10px 0',
      margin: '10px auto',
      minWidth: '850px',
    },
    classBorder: {
      // border: '1px solid red',
      margin: '10px 0',
      lineHeight: '56px',
    },
    borders: {
      position: 'relative',
      border: '1px solid #a2a294',
      margin: '10px 0',
      height: '56px',
    },
    titlePosition: {
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      position: 'absolute',
      top: '-10px',
      left: '6px',
      backgroundColor: '#FFFFFF',
    },
    cardWidth: {
      width: '100%',
      height: '56px',
      paddingTop: '8px',
    },
    itemML: {
      lineHeight: '42px',
    },
    salaryBorder: {
      padding: '10px',
      background: '#f5f5f5',
    },
    buttonPosition: {
      margin: '20px',
    },
    buttonAdd: {
      margin: '20px auto',
      width: '30%',
      minWidth: '100px',
    },
    borderMargin: {
      margin: '10px 0',
    },
    borderMarginTwo: {
      height: '56px',
    },
    iconLineHeight: {
      margin: '30px 0',
    },
    settlement: {
      margin: '10px 50px 10px 0',
    },
    errorBorder: {
      border: '1px solid #f44336',
    },
    errorLabelColor: {
      color: '#f44336',
    },
    h3: {
      margin: '1rem 0',
      textAlign: 'center',
    },
  });

const companyPublishProjectView = ({
  classes,
  showView = false,
  onClose,
  editData = false,
  editOpen,
  enterBidding = false,
  showComment = false,
  adminShowView = false,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const { SHOW_CHILD } = Cascader;
  const [selectProvinceChar, setSelectProvinceChar] = useState<string>('');
  const [selectCityChar, setSelectCityChar] = useState<string>('');

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      CompanyRequestInfo: createCompanyRequestInfo(),
      DemandCertificateInfo: [createDemandCertificateInfo()],
      languageInfoList: [createConsultantLanguageInfo()],
    },
    mode: 'onChange',
  });
  const { errors, isValid, isDirty } = useFormState({ control });

  const [manyProject, setManyProject] = useState(false);
  const [radioValue, setRadioValue] = React.useState('singleSalary');

  const [remuneration, setRemuneration] = useState(false);
  const [clearRemuneration, setClearRemuneration] = useState('');
  const [lastRadio, setLastRadio] = useState('');
  const radioHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastRadio(radioValue);
    setClearRemuneration('');
    const isSingleDescription = getValues(
      'CompanyRequestInfo.remunerationInfo.singleSalaryDescription'
    );
    const isSingleMin = getValues('CompanyRequestInfo.remunerationInfo.singleSalaryMin');
    const isSingleMax = getValues('CompanyRequestInfo.remunerationInfo.singleSalaryMax');
    const isMonthDescription = getValues(
      'CompanyRequestInfo.remunerationInfo.monthlySalaryDescription'
    );
    const isMonthMin = getValues('CompanyRequestInfo.remunerationInfo.monthlySalaryMin');
    const isMonthMax = getValues('CompanyRequestInfo.remunerationInfo.monthlySalaryMax');
    const isOtherCycle = getValues('CompanyRequestInfo.remunerationInfo.otherSalaryCycle');
    const isOtherDescription = getValues(
      'CompanyRequestInfo.remunerationInfo.otherSalaryDescription'
    );
    if (event.target.value == 'singleSalary') {
      if (isMonthDescription || isMonthMin || isMonthMax) {
        setClearRemuneration('isMonth');
        setRemuneration(true);
      }
      if (isOtherCycle || isOtherDescription) {
        setClearRemuneration('isOther');
        setRemuneration(true);
      }
    }
    if (event.target.value == 'monthlySalary') {
      if (isSingleDescription || isSingleMin || isSingleMax) {
        setClearRemuneration('isSingle');
        setRemuneration(true);
      }
      if (isOtherCycle || isOtherDescription) {
        setClearRemuneration('isOther');
        setRemuneration(true);
      }
    }
    if (event.target.value == 'otherSalary') {
      if (isMonthDescription || isMonthMin || isMonthMax) {
        setClearRemuneration('isMonth');
        setRemuneration(true);
      }
      if (isSingleDescription || isSingleMin || isSingleMax) {
        setClearRemuneration('isSingle');
        setRemuneration(true);
      }
    }
    setRadioValue(event.target.value);
  };

  const clearRemunerationInfo = () => {
    switch (clearRemuneration) {
      case 'isSingle':
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryDescription', '');
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryMax', null);
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryMin', null);
        break;
      case 'isMonth':
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryDescription', '');
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryMax', null);
        setValue('CompanyRequestInfo.remunerationInfo.singleSalaryMin', null);
        break;
      case 'isOther':
        setValue('CompanyRequestInfo.remunerationInfo.otherSalaryCycle', '');
        setValue('CompanyRequestInfo.remunerationInfo.otherSalaryDescription', '');
        break;
      default:
        break;
    }
    setRemuneration(false);
  };
  const [isPublishDialog, setIsPublishDialog] = useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const { fields: languageInfoList, append, remove } = useFieldArray({
    control,
    name: 'CompanyRequestInfo.languageInfoList',
  });

  const {
    fields: certificateInfoList,
    append: certificateInfoListAppend,
    remove: certificateInfoListRemove,
  } = useFieldArray({
    control,
    name: 'CompanyRequestInfo.certificateInfoList',
  });

  const falseWorkYear = {
    work1to3: false,
    work3to5: false,
    work5to8: false,
    work8to15: false,
    workAbove15: false,
  };
  const trueWorkYear = {
    work1to3: true,
    work3to5: true,
    work5to8: true,
    work8to15: true,
    workAbove15: true,
  };
  // 工作经验
  const [selectAllWorkYear, setSelectAllWorkYear] = useState(false);
  const [workingYear, setWorkingYear] = useState(falseWorkYear);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'SelectAllWorkYear') {
      setSelectAllWorkYear(event.target.checked);
      if (event.target.checked) {
        setWorkingYear(trueWorkYear);
        // setConsultantWorkYearDisable(false);
        // setWorkYearValueStart('');
        // setWorYearValueEnd('');
      } else setWorkingYear(falseWorkYear);
    }
    // if (event.target.name == 'selectAllEducational') {
    //   setSelectAllEducational(event.target.checked);
    //   if (event.target.checked) {
    //     setEducationState(trueEducation);
    //   } else setEducationState(falseEducation);
    // }
  };

  const handleLanguageInfoDelete = (index?: number | number[]) => {
    remove(index);
  };

  const handleCertificateInfoDelete = (index?: number | number[]) => {
    certificateInfoListRemove(index);
  };
  const workYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkingYear({ ...workingYear, [event.target.name]: event.target.checked });
  };

  const addProjectRequest = async () => {
    // setIsPublishDialog(true);
    // navigate('/company/projectPreview');
    // return;
    if (isDirty) {
      trigger('CompanyRequestInfo');
      trigger('DemandCertificateInfo');
      trigger('languageInfoList');
      if (isValid) {
        const data = {
          workload: getValues('CompanyRequestInfo.workload'),
          workLocationDistrict: getValues('CompanyRequestInfo.workLocationDistrict'),
          industry: JSON.stringify(getValues('CompanyRequestInfo.industry')),
          professionalClass: JSON.stringify(getValues('CompanyRequestInfo.professionalClass')),
          projectEndTime: getValues('CompanyRequestInfo.projectEndTime'),
          projectStartTime: getValues('CompanyRequestInfo.projectStartTime'),
          projectName: getValues('CompanyRequestInfo.projectName'),
          remunerationInfo: getValues('CompanyRequestInfo.remunerationInfo'),
          status: 'SUBMITTED',
          workLocationAddress: getValues('CompanyRequestInfo.workLocationAddress'),
          workLocationCity: getValues('CompanyRequestInfo.workLocationCity'),
          workLocationProvince: getValues('CompanyRequestInfo.workLocationProvince'),
          workScenario: JSON.stringify(getValues('CompanyRequestInfo.workScenario')),
          languageInfoList: getValues('languageInfoList'),
          certificateInfoList: getValues('DemandCertificateInfo'),
        };
        const value = await dispatch(addCompanyRequest(data));
        setTimeout(() => {
          navigate('/projectRequest/projectPreview/' + '#' + value.id);
        }, 2500);
      } else {
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: '输入信息格式不正确请检查',
          })
        );
      }
    }
  };

  useEffect(() => {
    // 获取锚点元素
    const anchorElement = document.getElementById('comment');
    // 检查锚点元素是否存在
    if (anchorElement && showComment) {
      // 使用scrollIntoView方法滚动到锚点
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <>
      <Helmet title="发布项目"></Helmet>
      <h3 className={classes.h3}>发布需求</h3>
      <Grid xs={8} className={classes.childrenItem} container justifyContent="center">
        <Grid item xs={11} className={classes.borderMargin} container>
          <Grid xs={11}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  disabled={showView || showComment || adminShowView}
                  id="LastName"
                  label="需求名称"
                  error={!!errors?.CompanyRequestInfo?.projectName}
                  helperText={!!errors?.CompanyRequestInfo?.projectName && '请填写正确的输入值'}
                  {...field}
                />
              )}
              name="CompanyRequestInfo.projectName"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={11} className={classes.borderMargin} container>
          <Grid xs={11}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={false}
                  multiline
                  disabled={showView || showComment || adminShowView}
                  minRows={4}
                  id="workContent"
                  label="项目内容"
                  error={!!errors?.CompanyRequestInfo?.projectContent}
                  helperText={!!errors?.CompanyRequestInfo?.projectContent && '请输入项目内容'}
                  {...field}
                />
              )}
              name="CompanyRequestInfo.projectContent"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={11} container className={classes.borderMargin}>
          <Grid item xs={2} className={classes.classBorder}>
            工作地址*
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={!!errors.CompanyRequestInfo?.workLocationProvince}
                >
                  <InputLabel id="label-nowAddress-province">省</InputLabel>
                  <Select
                    disabled={showView || showComment || adminShowView}
                    labelId="workLocationProvince"
                    id="province"
                    label="行政省"
                    value={value}
                    name={name}
                    inputRef={ref}
                    // disabled={summaryMode || verifyDisabled}
                    // readOnly={summaryMode || verifyDisabled}
                    onChange={e => {
                      onChange(e);
                      setSelectProvinceChar(e.target.value as string);
                      setSelectCityChar('');
                      // setValue('basicInfo.nowAddressDistrict', '');
                      // setValue('basicInfo.nowAddressCity', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -请选择-
                    </MenuItem>
                    {[...PROVINCE].map(province => {
                      return (
                        <MenuItem key={province.code} value={province.code}>
                          {province.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!!errors.CompanyRequestInfo?.workLocationProvince && (
                    <FormHelperText>选择不能为空</FormHelperText>
                  )}
                </FormControl>
              )}
              name="CompanyRequestInfo.workLocationProvince"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={!!errors.CompanyRequestInfo?.workLocationCity}
                >
                  <InputLabel id="workLocationCity">市</InputLabel>
                  <Select
                    labelId="workLocationCity"
                    id="province"
                    label="行政市"
                    disabled={showView || showComment || adminShowView}
                    value={value}
                    name={name}
                    inputRef={ref}
                    // disabled={summaryMode || verifyDisabled}
                    // readOnly={summaryMode || verifyDisabled}
                    onChange={e => {
                      onChange(e);
                      setSelectCityChar(e.target.value as string);
                      // setValue('basicInfo.nowAddressDistrict', '');
                      // setValue('basicInfo.nowAddressCity', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -请选择-
                    </MenuItem>
                    {[...CITY]
                      .filter(city => city.provinceCode === selectProvinceChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {!!errors.CompanyRequestInfo?.workLocationCity && (
                    <FormHelperText>选择不能为空</FormHelperText>
                  )}
                </FormControl>
              )}
              name="CompanyRequestInfo.workLocationCity"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControl margin="normal" variant="outlined" fullWidth>
                  <InputLabel id="workLocationDistrict">区县</InputLabel>
                  <Select
                    labelId="workLocationDistrict"
                    id="province"
                    label="行政区县"
                    // disabled={showView || showComment || adminShowView}
                    value={value}
                    name={name}
                    inputRef={ref}
                    onChange={e => {
                      onChange(e);
                      // setSelectProvinceChar(e.target.value);
                      // setValue('basicInfo.nowAddressDistrict', '');
                      // setValue('basicInfo.nowAddressCity', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -请选择-
                    </MenuItem>
                    {[...countyLess]
                      .filter(city => city.cityCode === selectCityChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
              name="CompanyRequestInfo.workLocationDistrict"
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={11} container className={classes.borderMargin}>
          <Grid item xs={11}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="LastName"
                  label="详细地址"
                  disabled={showView || showComment || adminShowView}
                  error={!!errors.CompanyRequestInfo?.workLocationAddress}
                  helperText={!!errors.CompanyRequestInfo?.workLocationAddress && '请填写详细地址'}
                  {...field}
                />
              )}
              name="CompanyRequestInfo.workLocationAddress"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={11} container>
          <Grid
            item
            xs={11}
            className={
              errors.CompanyRequestInfo?.professionalClass
                ? `${classes.borders} ${classes.errorBorder}`
                : classes.borders
            }
          >
            <span
              className={
                errors.CompanyRequestInfo?.professionalClass
                  ? `${classes.titlePosition} ${classes.errorLabelColor}`
                  : classes.titlePosition
              }
            >
              行业*
            </span>
            <Controller
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.CompanyRequestInfo?.industry}
                >
                  <Cascader
                    bordered={false}
                    placeholder="行业 选择"
                    className={classes.cardWidth}
                    options={dataIndustry}
                    disabled={showView || showComment || adminShowView}
                    multiple
                    size="large"
                    maxTagCount="responsive"
                    showCheckedStrategy={SHOW_CHILD}
                    {...field}
                  />
                  {!!errors.CompanyRequestInfo?.industry && (
                    <FormHelperText>行业选择不能为空</FormHelperText>
                  )}
                </FormControl>
              )}
              name={`CompanyRequestInfo.industry`}
              rules={{ required: true }}
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid item xs={11} container className={classes.borderMargin}>
          <Grid xs={11}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="LastName"
                  label="工作量"
                  disabled={showView || showComment || adminShowView}
                  error={!!errors.CompanyRequestInfo?.workload}
                  helperText={!!errors.CompanyRequestInfo?.workload && '工作量不能为空'}
                  {...field}
                />
              )}
              name="CompanyRequestInfo.workload"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={11} container>
          <Grid
            item
            xs={11}
            className={
              errors.CompanyRequestInfo?.professionalClass
                ? `${classes.borders} ${classes.errorBorder}`
                : classes.borders
            }
          >
            <span
              className={
                errors.CompanyRequestInfo?.professionalClass
                  ? `${classes.titlePosition} ${classes.errorLabelColor}`
                  : classes.titlePosition
              }
            >
              职能*
            </span>
            <Controller
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.CompanyRequestInfo?.professionalClass}
                >
                  <Cascader
                    bordered={false}
                    placeholder="职能选择"
                    className={classes.cardWidth}
                    options={specialData}
                    multiple
                    disabled={showView || showComment || adminShowView}
                    size="large"
                    maxTagCount="responsive"
                    showCheckedStrategy={SHOW_CHILD}
                    {...field}
                  />
                  {!!errors.CompanyRequestInfo?.professionalClass && (
                    <FormHelperText>职能选择不能为空</FormHelperText>
                  )}
                </FormControl>
              )}
              name={'CompanyRequestInfo.professionalClass'}
              rules={{ required: true }}
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid xs={11} className={classes.borderMargin} container>
          <Grid item xs={3} className={classes.itemML}>
            工作方式：
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      {...field}
                      checked={field.value}
                      disabled={showView || showComment || adminShowView}
                      name="onsite"
                    />
                  }
                  label="现场"
                />
              )}
              name={'CompanyRequestInfo.workScenario.onsite'}
              control={control}
            ></Controller>
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      {...field}
                      checked={field.value}
                      disabled={showView || showComment || adminShowView}
                      name="remote"
                    />
                  }
                  label="远程"
                />
              )}
              name={'CompanyRequestInfo.workScenario.remote'}
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ContactSupportIcon />
          </Grid>
        </Grid>
        <Grid xs={11} container className={classes.borderMargin}>
          <Grid item xs={1} className={classes.itemML}>
            资深度：
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllWorkYear}
                  onChange={handleChange}
                  name="SelectAllWorkYear"
                  color="primary"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="全部"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workingYear.work1to3}
                  onChange={workYearChange}
                  name="work1to3"
                  color="primary"
                  value="1-3"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="初级助理（1-3年）"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workingYear.work3to5}
                  onChange={workYearChange}
                  name="work3to5"
                  color="primary"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="专员及助理经理（3-5年）"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workingYear.work5to8}
                  onChange={workYearChange}
                  name="work5to8"
                  color="primary"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="顾问及经理（5-8年）"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workingYear.work8to15}
                  onChange={workYearChange}
                  name="work8to15"
                  color="primary"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="资深顾问及资深经理（8-15年）"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workingYear.workAbove15}
                  onChange={workYearChange}
                  name="workAbove15"
                  color="primary"
                  disabled={showView || showComment || adminShowView}
                />
              }
              label="执行顾问及总监（15年+）"
            />
          </Grid>
          <Grid xs={1}>
            <ContactSupportIcon />
          </Grid>
        </Grid>
        <Grid xs={11} className={(classes.borderMargin, classes.salaryBorder)} container>
          <Grid container xs={11} spacing={2}>
            {certificateInfoList.length > 0
              ? certificateInfoList.map((field, index) => {
                  return (
                    <CertificateInfoListView
                      key={field.id}
                      index={index}
                      control={control}
                      errors={errors}
                      summaryMode={showView || showComment || adminShowView}
                      remove={handleCertificateInfoDelete}
                    />
                  );
                })
              : certificateInfoListAppend(createDemandCertificateInfo())}
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              disabled={showView || showComment || adminShowView}
              onClick={() => {
                certificateInfoListAppend(createDemandCertificateInfo());
              }}
            >
              添加证书
            </Button>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid xs={11} className={(classes.borderMargin, classes.salaryBorder)} container>
          <Grid container xs={11} spacing={2}>
            {languageInfoList.length > 0
              ? languageInfoList.map((field, index) => {
                  return (
                    <LanguageInputView
                      key={field.id}
                      index={index}
                      control={control}
                      errors={errors}
                      summaryMode={showView || showComment || adminShowView}
                      // setValue={setValue}
                      remove={handleLanguageInfoDelete}
                      append={createConsultantLanguageInfo}
                    />
                  );
                })
              : append(createConsultantLanguageInfo())}
            <Button
              variant="contained"
              color="primary"
              disabled={showView || showComment || adminShowView}
              className={classes.buttonAdd}
              onClick={() => {
                append(createConsultantLanguageInfo());
              }}
            >
              添加语言
            </Button>
          </Grid>
          <Grid xs={1}>
            <ContactSupportIcon />
          </Grid>
        </Grid>

        <Grid container xs={11} className={classes.borderMargin} justifyContent="space-between">
          <FormControl margin="normal" variant="outlined" fullWidth>
            <FormLabel component="legend">项目预算</FormLabel>
            <RadioGroup aria-label="findingJob" row value={radioValue} onChange={radioHandleChange}>
              <FormControlLabel
                value="singleSalary"
                disabled={showView || showComment || adminShowView}
                control={<Radio />}
                label="单项结算"
              />
              <ContactSupportIcon className={classes.settlement} />
              <FormControlLabel
                value="monthlySalary"
                disabled={showView || showComment || adminShowView}
                control={<Radio />}
                label="月度结算"
              />
              <ContactSupportIcon className={classes.settlement} />
              <FormControlLabel
                value="otherSalary"
                disabled={showView || showComment || adminShowView}
                control={<Radio />}
                label="其他"
              />
              <ContactSupportIcon className={classes.settlement} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container xs={11} className={(classes.borderMargin, classes.salaryBorder)}>
          {radioValue == 'singleSalary' && (
            <Grid container xs={12} justifyContent="space-between">
              <Grid xs={5}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="预计最低预算"
                      type="number"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.singleSalaryMin'}
                  control={control}
                ></Controller>
              </Grid>
              <Grid xs={5}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="预计最高预算"
                      type="number"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.singleSalaryMax'}
                  control={control}
                ></Controller>
              </Grid>
              <Grid xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="结算周期"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.singleSalaryDescription'}
                  control={control}
                ></Controller>
              </Grid>
            </Grid>
          )}
          {radioValue == 'monthlySalary' && (
            <Grid container xs={12} justifyContent="space-between">
              <Grid xs={5}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="预计最低预算"
                      type="number"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.monthlySalaryMin'}
                  control={control}
                ></Controller>
              </Grid>
              <Grid xs={5}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="预计最高预算"
                      type="number"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.monthlySalaryMax'}
                  control={control}
                ></Controller>
              </Grid>
              <Grid xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="结算周期"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.monthlySalaryDescription'}
                  control={control}
                ></Controller>
              </Grid>
            </Grid>
          )}
          {radioValue == 'otherSalary' && (
            <Grid container xs={12} justifyContent="space-between">
              <Grid xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="描述"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.otherSalaryDescription'}
                  control={control}
                ></Controller>
              </Grid>
              <Grid xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="LastName"
                      disabled={showView || showComment || adminShowView}
                      label="结算周期"
                      {...field}
                    />
                  )}
                  name={'CompanyRequestInfo.remunerationInfo.otherSalaryCycle'}
                  control={control}
                ></Controller>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          xs={11}
          className={classes.borderMargin}
          spacing={2}
          justifyContent="space-between"
        >
          <Grid xs={5}>
            <Controller
              render={({ field }) => (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      format="yyyy-MM-dd"
                      views={['date']}
                      // views={['year', 'month']}
                      id="date-picker-start"
                      label="开始时间"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      error={!!errors.CompanyRequestInfo?.projectStartTime}
                      helperText={
                        !!errors.CompanyRequestInfo?.projectStartTime && '开始时间不能为空'
                      }
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}
              name={`CompanyRequestInfo.projectStartTime`}
              rules={{ required: true }}
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={5}>
            <Controller
              render={({ field }) => (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      format="yyyy-MM-dd"
                      id="date-picker-end"
                      label="结束时间"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}
              name={`CompanyRequestInfo.projectEndTime`}
              control={control}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        {enterBidding && (
          <Grid xs={11} container className={classes.borderMargin} justifyContent="center">
            <Grid xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus={false}
                multiline
                minRows={4}
                label="其他附加信息"
              />
            </Grid>
            <Grid xs={12}>签约人：xxx</Grid>
          </Grid>
        )}
        <Grid xs={11} container className={classes.borderMargin} justifyContent="center">
          {!showView && !editData && !enterBidding && !showComment && !adminShowView && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={showView}
              className={classes.buttonPosition}
              onClick={addProjectRequest}
            >
              确认发布
            </Button>
          )}
          {editData && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={showView}
              className={classes.buttonPosition}
              onClick={() => {
                setIsEditDialog(true);
              }}
            >
              确认修改
            </Button>
          )}
          {enterBidding && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.buttonPosition}
              onClick={() => {
                onClose ? onClose() : '';
              }}
            >
              发布邀约
            </Button>
          )}
          {showView && (
            <>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={!showView}
                className={classes.buttonPosition}
                onClick={() => {
                  // setIsCancelDialog(true);
                  onClose ? onClose() : '';
                  editOpen ? editOpen() : '';
                }}
              >
                编辑项目
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={!showView}
                className={classes.buttonPosition}
                onClick={() => {
                  setIsCancelDialog(true);
                }}
              >
                取消发布
              </Button>
            </>
          )}
          {adminShowView && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.buttonPosition}
              onClick={() => {
                setIsCancelDialog(true);
              }}
            >
              关闭项目
            </Button>
          )}
        </Grid>
      </Grid>
      {showComment && (
        <div id="comment">
          <ConsultantComment></ConsultantComment>
        </div>
      )}

      <CompanyPublishProjectDialog
        isOpen={isPublishDialog}
        handleEdit={() => {
          setIsPublishDialog(false);
        }}
        handleDialogClose={() => {
          setIsPublishDialog(false);
        }}
      >
        <div>
          <Checkbox
            checked={manyProject}
            onChange={() => {
              setManyProject(!manyProject);
            }}
            name="manyProject"
            color="primary"
          />
          是否是多人项目？
        </div>
      </CompanyPublishProjectDialog>
      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
      ></CancelCompanyPublishProjectDialog>
      <EditCompanyPublishProjectDialog
        isOpen={isEditDialog}
        handleEdit={() => {
          setIsEditDialog(false);
          editOpen ? editOpen() : '';
        }}
        handleDialogClose={() => {
          setIsEditDialog(false);
          editOpen ? editOpen() : '';
        }}
      ></EditCompanyPublishProjectDialog>

      <Dialog
        open={remuneration}
        scroll="paper"
        onClose={() => {
          setRemuneration(false);
        }}
      >
        <DialogTitle>修改项目预算结算方式</DialogTitle>
        <DialogContent>
          确认要修改项目预算结算方式？确认将清除之前保存的项目预算结算方式。
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={clearRemunerationInfo} variant="contained" color="primary">
            是
          </Button>
          <Button
            onClick={() => {
              setRemuneration(false);
              lastRadio ? setRadioValue(lastRadio) : '';
            }}
            variant="contained"
            color="primary"
          >
            否
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  showView?: boolean;
  onClose?: Function;
  editData?: boolean;
  editOpen?: Function;
  enterBidding?: boolean;
  showComment?: boolean;
  adminShowView?: boolean;
}

export default withStyles(styles)(companyPublishProjectView);
