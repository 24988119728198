import React from 'react';

import Layout from '../../layout/AdminLayout';
import CompanyPublishProjectView from '../../views/admin/CompanyPublishProjectView';

const ProjectList = (): React.ReactElement => {
  return (
    <Layout>
      <CompanyPublishProjectView></CompanyPublishProjectView>
    </Layout>
  );
};

export default ProjectList;
